import React from 'react';
import { LOGGEDIN_LINKS, LOGO_URL, LOGO_SR_TEXT } from '@web/molecules';
import { FOOTER_CONTENT } from '../../testing/constants';
import {
  freshModalsContent,
  dryModalsContent,
} from '../../testing/modalConstants';
import dogInBoxEmpty from '../../images/dog-in-box-empty.png';
import { MEAL_PREP_TYPES } from '../../testing/account';
import { FRESH_RECIPES, DRY_RECIPES } from '../constants';

export const MEALPREP_SELECTION_SLUG = 'mealprep';
export const RECIPES_BAKED_SLUG = 'baked';
export const RECIPES_FRESH_SLUG = 'fresh';
export const SUMMARY_SLUG = 'summary';
export const ADDONS_SELECTION_SLUG = 'extras';
export const PLAN_CHANGE_SUCCESS_SLUG = 'success';
export const QUIZ_SLUG = 'quiz';
export const PAYMENT_SLUG = 'payment';
export const DELIVERY_SLUG = 'delivery';

export const VALID_SLUGS = [
  MEALPREP_SELECTION_SLUG,
  RECIPES_BAKED_SLUG,
  RECIPES_FRESH_SLUG,
  SUMMARY_SLUG,
  QUIZ_SLUG,
  ADDONS_SELECTION_SLUG,
  PLAN_CHANGE_SUCCESS_SLUG,
  PAYMENT_SLUG,
  DELIVERY_SLUG,
];

export const STATE = {
  initial: 'account',
  quiz: 'quiz',
  mealprep: 'mealprep',
  fresh: 'fresh',
  baked: 'baked',
  summary: 'summary',
  success: 'success',
  payment: 'payment',
  delivery: 'delivery',
};

export const ACTION_TYPES = {
  toInitial: 'toInitial',
  toMealPrep: 'toMealPrep',
  selectedMealPrep: 'selectedMealPrep',
  selectedFreshRecipes: 'selectedFreshRecipes',
  selectedBakedRecipes: 'selectedBakedRecipes',
  toEditBaked: 'toEditBaked',
  toAddPupQuiz: 'toAddPupQuiz',
  toPayment: 'toPayment',
  toDelivery: 'toDelivery',
  toSuccess: 'toSuccess',
  toSummary: 'toSummary',
  submitPupForm: 'submitPupForm',
  goBack: 'goBack',
};

export const ACTIONS = {
  toInitial: (payload = {}) => ({ payload, type: ACTION_TYPES.toInitial }),
  toAddPupQuiz: (payload = {}) => ({
    payload,
    type: ACTION_TYPES.toAddPupQuiz,
  }),
  toMealPrep: (payload = {}) => ({ payload, type: ACTION_TYPES.toMealPrep }),
  submitPupForm: (payload = {}) => ({
    payload,
    type: ACTION_TYPES.submitPupForm,
  }),
  toPayment: (payload = {}) => ({ payload, type: ACTION_TYPES.toPayment }),
  toDelivery: (payload = {}) => ({ payload, type: ACTION_TYPES.toDelivery }),
  toSuccess: (payload = {}) => ({ payload, type: ACTION_TYPES.toSuccess }),
  toSummary: (payload = {}) => ({ payload, type: ACTION_TYPES.toSummary }),
  selectedMealPrep: (payload = {}) => ({
    payload,
    type: ACTION_TYPES.selectedMealPrep,
  }),
  selectedFreshRecipes: (payload = {}) => ({
    payload,
    type: ACTION_TYPES.selectedFreshRecipes,
  }),
  selectedBakedRecipes: (payload = {}) => ({
    payload,
    type: ACTION_TYPES.selectedBakedRecipes,
  }),
  toEditBaked: (payload = {}) => ({
    payload,
    type: ACTION_TYPES.toEditBaked,
  }),
};

export const stepToAction = {
  [STATE.initial]: ACTIONS.toInitial,
  [STATE.quiz]: ACTIONS.toAddPupQuiz,
  [STATE.mealprep]: ACTIONS.toMealPrep,
  [STATE.fresh]: ACTIONS.selectedMealPrep,
  [STATE.baked]: ACTIONS.selectedMealPrep,
  [STATE.payment]: ACTIONS.toPayment,
  [STATE.delivery]: ACTIONS.toDelivery,
  [STATE.summary]: ACTIONS.toSummary,
  [STATE.success]: ACTIONS.toSuccess,
};

const LINKS = LOGGEDIN_LINKS;

const quizProps = {
  defaultValues: {
    name: '',
    gender: '',
    year: '',
    month: '',
    day: '',
    breeds: [],
    weight: '',
    idealWeight: '',
    activity: '',
    spayedStatus: '',
    allergies: [],
  },
  heading: 'Add pup',
  subheading: `Enter your pet's information below so we can create their perfect plan.`,
  labelName: 'Name',
  labelGender: 'Sex',
  labelBirth: "Pup's birthday (If you're not sure, give us your best guess.)",
  labelBreeds: 'Breeds',
  labelSpayed: 'Spayed/Neutered?',
  labelAllergies: 'Allergies',
  weightLabel: 'Current weight',
  idealWeightLabel: 'Ideal weight',
  idealWeightHelp: "Pup's ideal weight in lbs (optional)",
  allergySearchLabel: 'Allergies',
  labelAllergySearch: 'Other (start typing)',
  labelActivityLevel: 'Activity Level',
  gender: [
    { name: 'Male', value: 'Male' },
    { name: 'Female', value: 'Female' },
  ],
  spayed: [
    { name: 'Yes', value: 'yes' },
    { name: 'No', value: 'no' },
  ],
  activityLevels: [
    {
      value: 'Low',
      label: 'Mellow',
    },
    {
      value: 'Moderate',
      label: 'Active',
    },
    {
      value: 'High',
      label: 'Very Active',
    },
  ],
  labelButtonSave: 'Next step',
  labelButtonCancel: 'Cancel',
  cancelUrl: '/account',
  footerContent: FOOTER_CONTENT,
  pupSuccessInfo: {
    image: {
      src: 'https://a.storyblok.com/f/89894/x/a9d62d7d07/dog-box.svg',
      alt: 'Dog & box illustration',
    },
    title: 'Success!',
    subtitle: 'WELCOME TO THE PACK, {{pupName}}!',
    infoText: 'Your first box is estimated to arrive on:',
    buttonLabel: 'Back to Dashboard',
  },
  links: LINKS,
  logoUrl: LOGO_URL,
  logoSrText: LOGO_SR_TEXT,
  urlTarget: '/account',
};

const content = {
  headline: 'Beef',
  fullIngredients:
    'Beef, beef heart, sweet potato, peas, potato, beef kidney, carrot, beef liver, spinach, chia seed, dicalcium phosphate, blueberries, fish oil, iodized salt, zinc gluconate, rosemary, vitamin E supplement, pyridoxine hydrochloride (vitamin B6), riboflavin (vitamin B2), potassium iodide',
  nutritionalInfo: [
    {
      label: 'Protein:',
      value: 'min 9%',
    },
    {
      label: 'Fat:',
      value: 'min 7%',
    },
    {
      label: 'Fiber:',
      value: 'max 2%',
    },
    {
      label: 'Moisture:',
      value: 'max 70%',
    },
  ],
  body: (
    <div>
      Hearty Beef Eats is formulated to meet the nutritional levels established
      by the AAFCO Dog Food Nutrient Profiles for All Life Stages, including
      growth of large size Dogs (greater than 70 lbs or more as an adult).
      Calorie content (calculated): 1540 kcal ME/kg.
    </div>
  ),
  image: {
    src: 'https://a.storyblok.com/f/89894/1280x1280/efd9790fcb/ollie_landingpage_product_beef-2.jpg',
    alt: 'beef',
    width: 420,
    height: 500,
  },
};

const mealPrepProps = {
  submitButtonText: 'Next step',
  cancelButtonText: 'Cancel',
  cancelTarget: '/account',
  onSubmit: () => {},
  mealPrepTypes: MEAL_PREP_TYPES,
  unavailableMealPrepTypes: [],
  links: LINKS,
  logoUrl: LOGO_URL,
  logoSrText: LOGO_SR_TEXT,
  headline: `How should we prepare {{pupName}}'s food?`,
  subheadline:
    'Each of our three prep options delivers fresh ingredients, delicious flavor, and complete nutrition — no byproducts or fillers, ever.',
};

const chooseFreshRecipeProps = {
  headline: `Choose {{pupName}}'s fresh recipes`,
  subheadline:
    'As a {{pupWeight}}-pound {{pupBreed}}, we made recipe recommendations to ensure their perfect portion. Continue with up to four selections below.',
  labelButtonSave: 'Next Step',
  cancelButtonText: 'Cancel',
  cancelTarget: '/account',
  footerContent: FOOTER_CONTENT,
  content,
  recipes: FRESH_RECIPES,
  cancelButtonVariant: 'secondary',
  submitButtonText: 'Next Step',
  links: LINKS,
  logoUrl: LOGO_URL,
  logoSrText: LOGO_SR_TEXT,
  petId: '1',
  modals: freshModalsContent,
};

const editAddressProps = {
  heading: 'Edit your delivery address',
  labelAddress: 'Address',
  labelApt: 'Apt/Suite (Optional)',
  labelZipCode: 'Zip code',
  labelCity: 'City',
  labelState: 'State',
  labelPhoneNumber: 'Phone number',
  labelSMSUpdates: 'Send me SMS updates',
  labelSMSTerms:
    '*I agree to receive promotional marketing text messages from Ollie at the number provided. Consent is not a condition to purchase. Reply HELP for help and STOP to cancel.  Msg frequency varies. Msg and data rates may apply.',
  labelResidentialAddress: 'This is a residential address',
  labelButtonSave: 'Save changes',
  labelButtonCancel: 'Cancel',
  cancelUrl: '/account/add-pup/summary/',
  links: LINKS,
  logoUrl: LOGO_URL,
  logoSrText: LOGO_SR_TEXT,
  footerContent: FOOTER_CONTENT,
  defaultValues: {
    address: '',
    apt: '',
    zipCode: '',
    city: '',
    state: '',
    phoneNumber: '',
    smsUpdates: false,
    residentialAddress: false,
  },
};

const editPaymentProps = {
  footerContent: FOOTER_CONTENT,
  headerTitleLabel: 'Edit your payment info',
  labelWithStripe: 'Pay securely with',
  labelNameCard: 'Name on card',
  labelCardNumber: 'Card Number',
  labelExpirationDate: 'Exp. date',
  labelCVC: 'CVV',
  placeholderNameCard: 'Name on card',
  placeholderCardNumber: '1234 1234 1234 1234',
  placeholderExpirationDate: 'MM/YY',
  placeholderCVC: 'CVV',
  saveBtnLabel: 'Save changes',
  cancelBtnLabel: 'Cancel',
  cancelBtnTarget: '/account/add-pup/summary/',
  links: LINKS,
  logoUrl: LOGO_URL,
  logoSrText: LOGO_SR_TEXT,
};

const successProps = {
  pupSuccessInfo: {
    image: {
      src: 'https://a.storyblok.com/f/89894/x/a9d62d7d07/dog-box.svg',
      alt: 'Dog & box illustration',
    },
    title: 'Success!',
    subtitle: 'WELCOME TO THE PACK, {{pupName}}!',
    infoText: 'Your first box is estimated to arrive on:',
    buttonLabel: 'Back to Dashboard',
  },
  links: LINKS,
  logoUrl: LOGO_URL,
  logoSrText: LOGO_SR_TEXT,
  urlTarget: '/account',
  petId: '1',
};

const planSummaryProps = {
  headline: `{{pupName}}'s Plan Summary`,
  subheadingText: 'Please review your plan details below.',
  recipesInfo: ['Lamb, Chicken, Beef, Turkey, Pork'],
  recipes: 'Lamb, Chicken, Beef, Turkey, Pork',
  confirmationText:
    'By selecting “Confirm” you are entering into a new subscription. You can skip or cancel deliveries at any time.',
  cancelButtonLabel: 'Cancel',
  discount: null,
  boxheadline: '-week starter box',
  period: 'Week',
  cancelTarget: '/account',
};

const chooseBakedRecipeProps = {
  headline: "Choose {{pupName}}'s baked recipes",
  subheadline:
    'As a {{pupWeight}}-pound {{pupBreed}}, we made recipe recommendations to ensure {{pupPronoun}} perfect portion. Continue with up to two selections below.',
  recipes: DRY_RECIPES,
  defaultRecipes: [],
  unavailableRecipes: [],
  modals: dryModalsContent,
  submitButtonText: 'Next',
  cancelTarget: '/account',
  cancelButtonText: 'Cancel',
  footerContent: FOOTER_CONTENT,
  links: LOGGEDIN_LINKS,
  logoUrl: LOGO_URL,
  logoSrText: LOGO_SR_TEXT,
};

export const NO_RECIPES_INFO = {
  headline: 'Doggone it!',
  subheadline:
    "We're unable to recommend a recipe for {{pupName}} at this time due to one or more allergies.",
  messagePreText: "You told us that {{pupName}} can't have:",
  messagePostText:
    "Double check this is accurate and adjust {{pupName}}'s allergy profile to see available recipes",
  buttonText: "Edit {{pupName}}'s allergies",
  secondaryButtonText: 'Cancel',
  image: {
    src: dogInBoxEmpty.src,
    alt: 'dog in empty box',
  },
  allergies: [
    { name: 'chicken' },
    { name: 'beef' },
    { name: 'lamb' },
    { name: 'turkey' },
    { name: 'pork' },
  ],
};

export const props = {
  quiz: quizProps,
  mealprep: mealPrepProps,
  fresh: chooseFreshRecipeProps,
  baked: chooseBakedRecipeProps,
  delivery: editAddressProps,
  payment: editPaymentProps,
  success: successProps,
  summary: planSummaryProps,
};
